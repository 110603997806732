<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark>AGREGAR ARTÍCULO</v-toolbar>
            <v-progress-linear
              v-if="loading"
              :loading="loading"
              indeterminate
              color="teal"
            ></v-progress-linear>
            <div class="text-h2 pa-8">
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Descripción"
                      v-model="description"
                      :rules="onlyRequired"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Cantidad"
                      v-model="quantity"
                      required
                      :rules="onlyRequired"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Precio"
                      v-model="price"
                      required
                      :rules="onlyRequired"
                      type="number"
                      step="any"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Total"
                      v-model="amount"
                      required
                      disabled
                      :rules="onlyRequired"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </div>
            <v-card-actions class="justify-end">
              <v-btn text color="red" @click="close()">Cancelar</v-btn>
              <v-btn
                text
                color="success"
                v-if="!update"
                @click="save()"
                :disabled="!valid"
                >Guardar</v-btn
              >
              <v-btn
                text
                color="success"
                v-if="update"
                @click="updateItem()"
                :disabled="!valid"
                >Actualizar</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import rules from "@/utils/form.rules.js";
import request from "@/services/requests";
export default {
  props: {
    dialog: { type: Boolean, required: true },
    voucherId: { type: Number, required: true },
    item: { type: Number, required: false },
    update: { type: Boolean, required: true },
  },
  data() {
    return {
      loading: false,
      valid: false,
      description: "",
      quantity: 0,
      price: 0,
      amount: 0,
      //rules
      onlyRequired: [rules.required],
    };
  },
  watch: {
    price: function () {
      this.amount = 0;
      if (this.price > 0 && this.quantity > 0) {
        this.amount = Number(
          parseFloat(this.quantity) * parseFloat(this.price, 2)
        ).toFixed(2);
      } else {
        this.amount = 0;
      }
    },
    quantity: function () {
      this.amount = 0;
      if (this.price > 0 && this.quantity > 0) {
        this.amount = Number(
          parseFloat(this.quantity) * parseFloat(this.price, 2)
        ).toFixed(2);
      } else {
        this.amount = 0;
      }
    },
    update: function (val) {
      if (val) {
        this.getDetail();
      }
    },
  },
  methods: {
    save() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        request
          .post("api/detail-vouchers/", {
            voucher_id: this.voucherId,
            description: this.description,
            quantity: this.quantity,
            price: this.price,
            amount: this.amount,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$toasted.global.info({
                message: "Registro Creado",
              });
              this.loading = false;
              this.close();
            } else {
              console.log(res);
            }
          });
      }
    },
    updateItem() {
      this.loading = true;
      request
        .patch("/api/detail-vouchers/" + this.item + "/", {
          description: this.description,
          quantity: this.quantity,
          price: this.price,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$toasted.global.info({
              message: "Operación realizada con éxito",
            });
            this.loading = false;
            this.close();
          } else {
            console.log(res);
          }
        });
    },
    getDetail() {
      this.loading = true;
      request.get("/api/detail-vouchers/" + this.item + "/").then((res) => {
        if (res.status == 200) {
          this.description = res.data.description;
          this.quantity = res.data.quantity;
          this.price = res.data.price;
          this.amount = res.data.amount;
          this.loading = false;
        }
      });
    },
    close() {
      this.$emit("close");
      this.$emit("saved");
      this.$refs.form.reset();
    },
  },
};
</script>